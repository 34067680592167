.rs-panel-collapsible .rs-panel-heading:before {
right: 0px;
}
.rs-panel-title {
  color: rgb(15, 74, 133);
}
.rs-drawer-dialog {
  overflow: auto;
  height: 100%;
}
.rs-drawer-content {
  overflow: auto;
  height: 100%;
}
div.reactmarkdown > p {
  font-size: 16px;
}
.nav-item {
  cursor: pointer;
}
.rs-pagination-btn-active > a {
  border: 1px solid grey;
}
